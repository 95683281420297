import { useEffect, useRef } from 'react';

import { useIntersectionObserver } from '@/lib/hooks';

import type { FunctionComponent } from 'react';
import type { TypeVideoProps } from './types';

/**
 * Video
 */
export const Video: FunctionComponent<TypeVideoProps> = ({
  className,
  sources,
  autoplay = false,
}: TypeVideoProps) => {
  const ref = useRef<HTMLVideoElement>(null);
  const entry = useIntersectionObserver(ref, {
    freezeOnceVisible: false,
  });
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    isVisible ? ref.current?.play() : ref.current?.pause();
  }, [isVisible]);

  return (
    <video
      preload="none"
      autoPlay={autoplay}
      loop
      muted
      playsInline
      ref={ref}
      disablePictureInPicture
      {...(className && { className })}
    >
      {sources.map((source, index) => (
        <source src={source.src} type={source.type} key={index} />
      ))}
    </video>
  );
};

Video.displayName = 'Video';
